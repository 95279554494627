import { FC, useMemo } from 'react';
import cx from 'classnames';
import Image from "next/image";
import Link from 'next/link';
import { useRouter } from 'next/router';
import { TAGGED_PRODUCTS } from 'components/ProductCard/ProductTags';
import { DEFAULT_BLUR_IMAGE_DATA_URL } from 'constants/blurImage';
import { formatVisiblePrice } from 'utils/common.utils';
import ProductTag from './ProductTag';
import { imageLoader } from 'functions/imageLoader';

interface RecommendedProductCardProps {
  title: string;
  image: string;
  path: string;
  price: any;
}

const DISCOUNT_RULES: {keyword: string, regions: string[], offPercentage: number}[] = [
	{
    keyword: 'Prescription Glasses',
    regions: [],
    offPercentage: 40
  },
  {
    keyword: 'Blue Light Filter Glasses',
    regions: [],
    offPercentage: 40
  },
  {
    keyword: 'Prescription Sunglasses',
    regions: [],
    offPercentage: 40
  },
  {
    keyword: 'Non-prescription Sunglasses',
		regions: [],
    offPercentage: 40
  }
];

const RecommendedProductCard: FC<RecommendedProductCardProps> = ({
  image,
  title,
  path,
  price
}) => {
  const router = useRouter();

  const visiblePrice = useMemo(
    () => price[(router.locale || '')?.toUpperCase()] || price.DEFAULT,
    [router.locale, price]
  );

  const tag = useMemo(() => {
    return TAGGED_PRODUCTS.find((tag) => tag.paths.includes(path) && tag.availableRegions.includes(router.locale as string));
  }, [path]);

  const discountRule = useMemo(
    () =>
      DISCOUNT_RULES.find(
        (rule) =>
          title.includes(rule.keyword) &&
          rule.regions.includes(router.locale as string),
        [title]
      ),
    [router.locale, title]
  );

  const discountedPrice = useMemo(() => {
    if (!discountRule) return visiblePrice;

    const [, rawPrice] = visiblePrice.split('$');

    const discount = (Number(rawPrice) * discountRule.offPercentage) / 100;

    return formatVisiblePrice(Number(rawPrice) - discount);
  }, [discountRule, visiblePrice]);

  return (
    (<Link
      prefetch={false}
      href={path}
      title={title}
      className="group flex h-full flex-col rounded-[8px] bg-white p-[8px] text-brand-black100 transition-shadow duration-500 sm:p-[20px] sm:pb-[24px] sm:hover:shadow-card">

      <div className="relative mb-[2px] h-[168px] overflow-hidden rounded-[8px]">
        {!!tag && <ProductTag {...tag} />}
        <Image
          placeholder="blur"
          fill
          style={{
              objectFit: "cover",
          }}
          sizes="230px"
          quality={75}
          loader={imageLoader}
          src={image}
          alt={title}
          title={title}
          blurDataURL={DEFAULT_BLUR_IMAGE_DATA_URL}
        />
      </div>
      <div className="flex flex-auto flex-col items-center justify-between">
        <span className="text-16px mt-[8px] flex-auto text-center leading-[17px]">
          {title}
        </span>
        <span className="mt-[8px] font-medium">
          {!!discountRule && 
              (visiblePrice.toLowerCase().includes("from") ? 
								<>
									<span className="text-brand-grey450">
										{visiblePrice.split(" ")[0]}
									</span>
									{" "}
									<span className="mr-[10px] text-brand-grey450 line-through">
										{visiblePrice.split(" ")[1]}
									</span> 
								</> 
								:
								<span className="mr-[10px] text-brand-grey450 line-through">
									{visiblePrice}
								</span>)
          }
          <span
            className={cx({
              'text-brand-green': discountRule
            })} dangerouslySetInnerHTML={{ __html: discountRule ? discountedPrice : visiblePrice }}>

          </span>
        </span>
        <span className="mt-[16px] h-[40px] w-[140px] rounded-[6px] border border-brand-grey400 text-center font-medium leading-[35px] transition-colors duration-500 sm:group-hover:text-brand-orange">
          View Product
        </span>
      </div>

    </Link>)
  );
};

export default RecommendedProductCard;
